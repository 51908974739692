var $ = require('jquery');

var $delayTime = 1000;
 
$(window).on('load', function(){
  var $loadingAnim = $('#loading'),
      $body = $('body');
 
    setTimeout( function(){
        
      $body.addClass('loaded');
  
      $loadingAnim.find('.loading__logo').on('transitionend', function( e ){ 
        $(this).parent().remove(); }); }, $delayTime );
});

var $header = $('#header');
// Nav Toggle Button
$('#toggle').click(function(){
    $header.toggleClass('is-open');
});

$('.btn').click(function(){
  $header.toggleClass('is-open');
});

// Nav Fixed
$(window).scroll(function() {
  if ($(window).scrollTop() > 300) {
      $header.addClass('is-fixed');
  } else {
      $header.removeClass('is-fixed');
  }
});

// #で始まるアンカーをクリックした場合に処理
$('a[href^="#"]').click(function() {
    // スクロールの速度
    var speed = 800; // ミリ秒
    // アンカーの値取得
    var href= $(this).attr("href");
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先を数値で取得
    var position = target.offset().top;
    // スムーススクロール
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
});
