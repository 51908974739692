import "./module/userAgentChecker.js"
import "./module/responsive.js"
import "./module/scroll.js"
import Swiper from 'swiper';

var mySwiper = new Swiper('.swiper-container', {
    loop: true,
    effect: 'fade',
    autoplay: {
        delay: 8000,
        disableOnInteraction: false,
    },
    speed: 5000,
    simulateTouch: false
});