var ScrollMagic = require('scrollmagic');

class ScrollFade {
    constructor() {
        this.ANIMATION_CLASS = 'is_anim';

        let $section = document.querySelectorAll('.scroll:not(.is_anim)');
        if($section.length === null) {
            return;
        }
        let controller = new ScrollMagic.Controller();
        for(let i = 0; i < $section.length; i++) {
            let scene = new ScrollMagic.Scene({
                triggerElement: $section[i],
                triggerHook: 'onEnter',
                reverse: false,
                offset: 100
            })
                .addTo(controller);
            scene.on('enter', ()=>{
                $section[i].classList.add(this.ANIMATION_CLASS);
            });
            scene.on('end', ()=>{
                scene.destroy(true);
            });
        }
    }
}

new ScrollFade();